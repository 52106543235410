import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import { WEMS_QUERY_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const WemsQueryHomePage = lazy(() =>
  import('eficia/views/wems-query/WemsQueryHomePage')
);

const WemsQueryCreatePage = lazy(() =>
  import('eficia/views/wems-query/WemsQueryCreatePage')
);

const WemsQueryReportPage = lazy(() =>
  import('eficia/views/wems-query/WemsQueryReportPage')
);

const WemsQueryCreateFromDataPage = lazy(() =>
  import('eficia/views/wems-query/WemsQueryCreateFromDataPage')
);

export default function WemsQueryRoutes() {
  return (
    <PrivateRoute
      path={WEMS_QUERY_PATH}
      render={({ match: { path } }) => (
        <>
          <Route path={path} component={WemsQueryHomePage} exact />
          <Route
            path={`${path}/create`}
            component={WemsQueryCreatePage}
            exact
          />
          <Route
            path={`${path}/create/:batchId`}
            component={WemsQueryCreateFromDataPage}
          />
          <Route
            path={`${path}/report/:batchId`}
            component={WemsQueryReportPage}
          />
        </>
      )}
    />
  );
}
