import styled from 'styled-components';

import { LetterLogo } from 'eficia/components/atoms/Logo';

export const Div = styled.div`
  display: flex;
  justify-content: ${({ sidebarToggle }) => (sidebarToggle ? 'center !important' : 'space-between')};
`;

export const StyledLetterLogo = styled(LetterLogo)`
  width: 22px;
`;
