import axiosConfig from './config/axiosConfig';

import { TRANSLATION_API } from './config/constants';

export default function translationsApi() {
  return {
    async getListLocales() {
      const query = await axiosConfig.get(`${TRANSLATION_API}/list-locales`);
      return query;
    },
    async exportTranslation() {
      const query = await axiosConfig.get(`${TRANSLATION_API}/export-csv`);
      return query;
    },
    async importTranslation(csv) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const query = await axiosConfig.post(
        `${TRANSLATION_API}/import-csv`,
        csv,
        config
      );
      return query;
    }
  };
}
