import { createMuiTheme } from '@material-ui/core';
import typography from './typography';

// import/no-unresolved, import/no-webpack-loader-syntax
/* eslint-disable */
import vars from '!!sass-vars-to-js-loader!../assets/core/_variables-mui.scss';

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: vars.primary
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2
    },
    secondary: {
      main: vars.secondary
    },
    error: {
      main: vars.red
    },
    success: {
      main: vars.green
    },
    warning: {
      main: vars.orange
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)'
    },
    contrastThreshold: 3,
    tonalOffset: 0.1
  },
  shape: {
    borderRadius: '0.5rem'
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      label: {
        fontSize: '14px'
      },
      // contained:{
      //   color: vars.inheritDefault1,
      //   backgroundColor: vars.primaryLight,
      // },
      containedSizeSmall: {
        padding: '4px 16px'
      },
      root: {
        textTransform: 'uppercase',
        fontWeight: '500',
        minWidth: 'auto'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px'
      },
      arrow: {
        color: vars.second
      }
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: vars.inheritDefault2
      }
    }
  },
  typography
});

export default MuiTheme;
