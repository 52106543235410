
import axiosConfig from './config/axiosConfig';

import { SLUGS_API, USER_API } from './config/constants';

export default function userApi() {
  return {
    async getSlugs() {
      const query = await axiosConfig.get(SLUGS_API);
      return query;
    },
    async getInfos() {
      const query = await axiosConfig.get(USER_API);
      return query;
    }
  };
}
