import { useCallback } from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

export function useFetchSlugs() {
  const client = eficiaClient();

  const fetcher = useCallback(() => {
    return client.user.getSlugs();
  }, [client.user]);

  const { data, error, isFetching } = useQuery(['user-slugs'], fetcher);

  return {
    data: data?.data,
    error,
    isFetching
  };
}
