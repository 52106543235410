import axiosConfig from './config/axiosConfig';
import { SITES_API } from './config/constants';

export default function sitesApi() {
  return {
    async getAll(options) {
      const query = await axiosConfig.get(
        `${SITES_API}/select-options?${options}`
      );
      return query;
    }
  };
}
