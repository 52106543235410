import React from 'react';
import { Modal, Box } from '@material-ui/core';

import { PulseLoader } from 'react-spinners';

function PulseModal() {
  return (
    <Modal open>
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <PulseLoader color="var(--primary)" loading />
      </Box>
    </Modal>
  );
}

PulseModal.propTypes = {
};

export default PulseModal;
