import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import { HARMONY_SCRIPTS_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const HarmonyScriptHomePage = lazy(() =>
  import('eficia/views/harmony-scripts/HarmonyScriptHomePage')
);
const HarmonyScriptCreatePage = lazy(() =>
  import('eficia/views/harmony-scripts/HarmonyScriptCreatePage')
);
const HarmonyScriptCreateFromModelPage = lazy(() =>
  import('eficia/views/harmony-scripts/HarmonyScriptCreateFromModelPage')
);
const HarmonyScriptReportPage = lazy(() =>
  import('eficia/views/harmony-scripts/HarmonyScriptReportPage')
);

export default function HarmonyScriptRoutes() {
  return (
    <PrivateRoute
      path={HARMONY_SCRIPTS_PATH}
      render={({ match: { path } }) => (
        <>
          <Route path={path} component={HarmonyScriptHomePage} exact />
          <Route path={`${path}/create`} component={HarmonyScriptCreatePage} />
          <Route
            path={`${path}/create-from-model/:batchId`}
            component={HarmonyScriptCreateFromModelPage}
          />
          <Route
            path={`${path}/report/:batchId`}
            component={HarmonyScriptReportPage}
          />
        </>
      )}
    />
  );
}
