import { formatDateToString } from 'eficia/utilities/date';

import axiosConfig from './config/axiosConfig';
import { SITE_ALERT_API, ALERT_API } from './config/constants';

export default function siteAlertsApi() {
  return {
    async getAll(search, params) {
      const requestRes = await axiosConfig.post(
        `${SITE_ALERT_API}${search}`,
        params
      );

      const {
        data,
        meta: { pagination }
      } = requestRes.data;

      return {
        data: data.map(({ changedAt, ...rest }) => ({
          changedAt: changedAt ? formatDateToString(changedAt) : '-',
          ...rest
        })),
        pagination: {
          countPage: pagination.total_pages,
          itemsCount: pagination.total
        }
      };
    },
    async getOne(siteAlertId, format = false) {
      const result = await axiosConfig.get(`${SITE_ALERT_API}/${siteAlertId}`);

      if (!format) {
        return result;
      }

      const {
        data: {
          data: { ruleGroups, ...alertData }
        }
      } = result;

      async function getRequest({
        addresses: addressesProps,
        tags: tagsProps,
        payload,
        name,
        type
      }) {
        const addresses =
          addressesProps?.data.length > 0
            ? addressesProps.data.map(({ address }) => address)
            : [];
        const tags =
          tagsProps?.data.length > 0
            ? tagsProps.data.map(({ name: tagName }) => tagName)
            : [];

        const descriptionRes = await axiosConfig
          .post(`${ALERT_API}/generate-rule-label`, {
            locale: 'fr',
            ruleType: type,
            ruleName: name,
            addresses,
            tags,
            rulePayload: payload
          })
          .then(function(response) {
            return response.data.data.label;
          });
        return descriptionRes;
      }

      const ruleDescriptionPromises = ruleGroups.data
        .map(ruleGroup => ruleGroup.alertRules.data.map(getRequest))
        .reduce((a, b) => a.concat(b), []);

      const descriptionArray = await Promise.all(ruleDescriptionPromises);
      return {
        alert: alertData,
        ruleGroups: ruleGroups.data.map((ruleGroup, groupIndex) => {
          const rules = ruleGroup.alertRules.data.map(
            ({ changedAt, type, state, id }, ruleIndex) => ({
              description: descriptionArray.shift(),
              date: changedAt ? formatDateToString(changedAt) : '-',
              type,
              state,
              id,
              hasAndBadge: ruleIndex < ruleGroup.alertRules.data.length - 1
            })
          );

          return { groupState: ruleGroup.state, rules };
        })
      };
    },
    async getHistory(id, search) {
      const query = await axiosConfig.get(
        `${SITE_ALERT_API}/history/${id}${search}`
      );
      return query;
    },
    async getRuleHistory(ruleId, search) {
      const query = await axiosConfig.get(
        `${SITE_ALERT_API}/history/rule/${ruleId}${search}`
      );
      return query;
    },
    async getRule(ruleId) {
      const query = await axiosConfig.get(`${SITE_ALERT_API}/rule/${ruleId}`);

      async function getRequest({
        addresses: addressesProps,
        tags: tagsProps,
        payload,
        name,
        type
      }) {
        const addresses =
          addressesProps?.data.length > 0
            ? addressesProps.data.map(({ address }) => address)
            : [];
        const tags =
          tagsProps?.data.length > 0
            ? tagsProps.data.map(({ name: tagName }) => tagName)
            : [];

        const descriptionRes = await axiosConfig
          .post(`${ALERT_API}/generate-rule-label`, {
            locale: 'fr',
            ruleType: type,
            ruleName: name,
            addresses,
            tags,
            rulePayload: payload
          })
          .then(function(response) {
            return response.data.data.label;
          });
        return descriptionRes;
      }

      const {
        addresses,
        name,
        payload,
        tags,
        type,
        changedAt,
        state
      } = query.data.data;

      const description = await getRequest({
        addresses,
        name,
        payload,
        tags,
        type
      });

      return {
        changedAt,
        description,
        type,
        state
      };
    },
    async getRuleHarmonyLog(ruleId) {
      const query = await axiosConfig.get(
        `${SITE_ALERT_API}/rule/harmony-log/${ruleId}`
      );
      return query;
    },
    async delete(siteAlertIds) {
      const query = await axiosConfig.delete(SITE_ALERT_API, {
        data: {
          siteAlertIds
        }
      });
      return query;
    },
    async subscribeAlerts(params) {
      const query = axiosConfig.post(`${SITE_ALERT_API}/subscription`, params);
      return query;
    },
    async deleteSubscriptions(siteSubscriptions) {
      const query = axiosConfig.delete(`${SITE_ALERT_API}/subscription`, {
        data: {
          siteSubscriptions
        }
      });
      return query;
    }
  };
}
