import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { ALERT_HOME_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const AlertManagementPage = lazy(() =>
  import('eficia/views/alert/ManagementPage')
);
const AlertManagementCreatePage = lazy(() =>
  import('eficia/views/alert/CreatePage')
);

const AlertManagementCreateFromModelPage = lazy(() =>
  import('eficia/views/alert/CreateFromModelPage')
);
const AlertManagementEditPage = lazy(() =>
  import('eficia/views/alert/EditPage')
);
const AlertDetailPageBis = lazy(() => import('eficia/views/alert/DetailPage'));
const AlertHistoryPageBis = lazy(() =>
  import('eficia/views/alert/HistoryPage')
);
const AlertRuleHistoryPageBis = lazy(() =>
  import('eficia/views/alert/RuleHistoryPage')
);
const AlertRuleDetailPageBis = lazy(() =>
  import('eficia/views/alert/RuleDetailPage')
);

const AlertConsultationPage = lazy(() =>
  import('eficia/views/alert/ConsultationPage')
);

const AlertSubscriptionPage = lazy(() =>
  import('eficia/views/alert/SubscriptionPage')
);

export default function AlertRoutes() {
  return (
    <PrivateRoute
      path={ALERT_HOME_PATH}
      render={({ match: { path }, location: { pathname: currentPath } }) => (
        <>
          {path === currentPath && <Redirect to={`${path}/management`} />}
          <Route
            path={`${path}/management`}
            component={AlertManagementPage}
            exact
          />
          <Route
            path={`${path}/management/create`}
            component={AlertManagementCreatePage}
          />
          <Route
            path={`${path}/management/edit/:id`}
            component={AlertManagementEditPage}
          />
          <Route
            path={`${path}/management/create-from-model/:id`}
            component={AlertManagementCreateFromModelPage}
          />
          <Route
            path={`${path}/consultation`}
            component={AlertConsultationPage}
            exact
          />
          <Route
            path={`${path}/consultation/:id`}
            component={AlertDetailPageBis}
            exact
          />
          <Route
            path={`${path}/consultation/:siteAlertId/history/rule/:ruleId`}
            component={AlertRuleHistoryPageBis}
            exact
          />
          <Route
            path={`${path}/consultation/:siteAlertId/rule/:ruleId`}
            component={AlertRuleDetailPageBis}
            exact
          />
          <Route
            path={`${path}/consultation/:siteAlertId/history`}
            component={AlertHistoryPageBis}
            exact
          />
          <Route
            path={`${path}/subscription`}
            component={AlertSubscriptionPage}
          />
        </>
      )}
    />
  );
}
