import styled from 'styled-components';
import { InputBase } from '@material-ui/core';

export const Input = styled(InputBase)`
  color: #fff;
  svg {
    color: #fff
  };
  
  & > div:focus {
    padding-left: 9px;
    border-radius: 0.2rem;
    padding: 10px 26px 10px 12px;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.10);
    border-color: #80bdff,
    
  }

  .MuiSelect-select:focus {
    background: transparent;
  }
`;
