export const ALERT_STATUS = [
  {
    value: true,
    label: 'alert.view.create.form.alert_state.enabled'
  },
  {
    value: false,
    label: 'alert.view.create.form.alert_state.disabled'
  }
];

export const GROUPED_TYPE_OF_VIEW = 'grouped';
export const EXPANDED_TYPE_OF_VIEW = 'expanded';

export const ALERT_TYPE_OF_VIEW = [
  {
    value: GROUPED_TYPE_OF_VIEW,
    label: 'alert.view.home.group.view'
  },
  {
    value: EXPANDED_TYPE_OF_VIEW,
    label: 'alert.view.home.site.view'
  }
];
