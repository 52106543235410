import React, { lazy } from 'react';

import { TRANSLATION_HOME_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const TranslationPage = lazy(() =>
  import('eficia/views/translation/TranslationPage')
);

export default function TranslationRoutes() {
  return (
    <PrivateRoute path={TRANSLATION_HOME_PATH} component={TranslationPage} />
  );
}
