import React, { lazy } from 'react';
import { SOFTWARE_BY_ALRIC_HOME_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const SoftwareByAlricHomePage = lazy(() =>
  import('eficia/views/software-by-alric/home/SoftwareByAlricHomePage')
);

export default function SoftwareByAlricRoutes() {
  return (
    <PrivateRoute
      path={SOFTWARE_BY_ALRIC_HOME_PATH}
      component={SoftwareByAlricHomePage}
    />
  );
}
