import { HARMONY_SCRIPT } from './config/constants';
import axiosConfig from './config/axiosConfig';

export default function harmonyScriptApi() {
  return {
    async getAll(search) {
      const query = await axiosConfig.get(`${HARMONY_SCRIPT}${search}`);
      return query;
    },
    async add(params) {
      const query = await axiosConfig.post(HARMONY_SCRIPT, params);
      return query;
    },
    async getOne(batchId) {
      const query = await axiosConfig.get(`${HARMONY_SCRIPT}/${batchId}`);
      return query;
    },
    async export(batchId) {
      const query = await axiosConfig.get(
        `${HARMONY_SCRIPT}/export/${batchId}`
      );
      return query;
    },
    async getUpdateRequestList(batchId, search) {
      const query = await axiosConfig.get(
        `${HARMONY_SCRIPT}/update-requests/${batchId}${search}`
      );
      return query;
    },
    async retryReport(batchId) {
      const query = await axiosConfig.post(
        `${HARMONY_SCRIPT}/${batchId}/retry`
      );
      return query;
    }
  };
}
