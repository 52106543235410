/* eslint-disable react/no-danger */
import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import useNotification from 'eficia/hooks/useNotification';

import { Text } from './styles/Notification.style';

export default function Notification() {
  const { notification, removeNotification } = useNotification();

  // Handle notification when click away
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    removeNotification();
  };

  if (!notification) {
    return null;
  }

  return (
    <Snackbar
      disableWindowBlurListener
      open={!!notification}
      autoHideDuration={notification.duration === 0 ? null : 10000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={notification.type}
      >
        <Text
          dangerouslySetInnerHTML={{
            __html: notification.message.toString()
          }}
        />
      </MuiAlert>
    </Snackbar>
  );
}
