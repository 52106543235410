import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';

const Footer = props => {
  const { sidebarToggle, footerFixed } = props;
  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--fixed': footerFixed,
          'app-footer--fixed__collapsed': sidebarToggle
        })}
      >
        <div className="app-footer--inner">
          <div>
            <span>Ensylog ©{new Date().getFullYear()}</span>
          </div>
        </div>
      </div>
    </>
  );
};

Footer.propTypes = {
  footerFixed: PropTypes.bool,
  sidebarToggle: PropTypes.bool
};

const mapStateToProps = state => ({
  footerFixed: state.ThemeOptions.footerFixed,
  footerShadow: state.ThemeOptions.footerShadow,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});
export default connect(mapStateToProps)(Footer);
