import { HARMONY_WRITING, HARMONY_WRITING_EXPORT } from './config/constants';
import axiosConfig from './config/axiosConfig';

export default function harmonyWritingtApi() {
  return {
    async getAll(search, params) {
      const query = await axiosConfig.post(
        `${HARMONY_WRITING}s${search}`,
        params
      );
      return query;
    },
    async getReportList(massObjectWritingId, search) {
      const query = await axiosConfig.get(
        `${HARMONY_WRITING}-report/${massObjectWritingId}${search}`
      );
      return query;
    },
    async retryReport(massObjectWritingId) {
      const query = await axiosConfig.post(
        `${HARMONY_WRITING}/${massObjectWritingId}/retry`
      );
      return query;
    },
    async export(massObjectWritingId) {
      const query = await axiosConfig.get(
        `${HARMONY_WRITING_EXPORT}-report/${massObjectWritingId}`
      );
      return query;
    },

    async getOne(massObjectWritingId) {
      const query = await axiosConfig.get(
        `${HARMONY_WRITING}/${massObjectWritingId}`
      );
      return query;
    },
    async add(params) {
      const query = await axiosConfig.post(HARMONY_WRITING, params);
      return query;
    }
  };
}
