import axios from 'axios';
import { GLOBAL_V2_LOGIN } from 'eficia/constants/paths';

const instance = axios.create({
  // injecter le cookie à chaque requête
  withCredentials: true
});

instance.interceptors.response.use(
  function(response) {
    // TODO: Formatter ici le retour d'api
    return response;
  },
  function(error) {
    // l'authentification est directement gérée par le back (JWT) qui nous renvoit une 403 si l'utilisateur n'est pas connecté.
    // Si l'utilisateur n'est pas connecté on le redirige sur global.
    if (error.response.status === 403) {
      window.location = GLOBAL_V2_LOGIN;
    }

    // retourner l'erreur
    return Promise.reject(error);
  }
);

export default instance;
