import React from 'react';

import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { library } from '@fortawesome/fontawesome-svg-core';
import { CookiesProvider } from 'react-cookie';
import CssBaseline from '@material-ui/core/CssBaseline';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import ScrollToTop from 'eficia/utilities/ScrollToTop';
import Routes from 'eficia/routes/Routes';

import configureStore from '../../../config/configureStore';
import configureI18n from '../../../config/configureI18n';

import '../../../assets/base.scss';
import MuiTheme from '../../../theme';

library.add(fas, far);

// add Sentry config
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://9fb13280c85c4aa2b1808f99f1226ff6@o339540.ingest.sentry.io/5279919'
  });
}

const store = configureStore();
export default function App() {
  // init config react-i18n
  configureI18n();

  // init config react-query
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <HashRouter>
            <CssBaseline />
            <ScrollToTop>
              <MuiThemeProvider theme={MuiTheme}>
                <ThemeProvider theme={MuiTheme}>
                  <Routes />
                </ThemeProvider>
              </MuiThemeProvider>
              <ReactQueryDevtools />
            </ScrollToTop>
          </HashRouter>
        </CookiesProvider>
      </QueryClientProvider>
    </Provider>
  );
}
