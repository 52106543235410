import axios from 'axios';

import { ALERTS_BASE_API } from './config/constants';

export default function alertsApi() {
  return {
    async getAll(search) {
      const query = await axios.get(`${ALERTS_BASE_API}/alerts${search}`);
      return query;
    },
    async addAlertParameters(params) {
      const query = await axios.post(`${ALERTS_BASE_API}/alerts`, params);
      return query;
    },
    async editAlertParameters(params, alertId) {
      const query = await axios.put(
        `${ALERTS_BASE_API}/alerts/${alertId}`,
        params
      );
      return query;
    },
    async addAlertRules(params) {
      const query = await axios.post(`${ALERTS_BASE_API}/alerts`, params);
      return query;
    },
    async delete(alertIds) {
      const query = axios.delete(`${ALERTS_BASE_API}/alerts/mass-delete`, {
        data: alertIds
      });
      return query;
    },
    async duplicate(data) {
      const query = axios.post(`${ALERTS_BASE_API}/duplicate-alerts`, data);
      return query;
    },
    async getHistory(alertId) {
      const query = await axios.get(
        `${ALERTS_BASE_API}/alert-state-logs/${alertId}`
      );
      return query;
    },
    async getRuleHistory(ruleId) {
      const query = await axios.get(
        `${ALERTS_BASE_API}/alert-rule-state-logs/${ruleId}`
      );
      return query;
    },
    async getAlertRules(alertId) {
      const query = await axios.get(
        `${ALERTS_BASE_API}/alerts/${alertId}/alert-rules`
      );
      return query;
    }
  };
}
