import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { ENEDIS_SERVICE_SUBSCRIPTION_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const EnedisServiceSubscriptionDemandListPage = lazy(() =>
  import('eficia/views/enedis-service-subscription/DemandListPage')
);
const EnedisServiceSubscriptionCreateDemandPage = lazy(() =>
  import('eficia/views/enedis-service-subscription/DemandCreatePage')
);

const EnedisServiceSubscriptionDemandReportPage = lazy(() =>
  import('eficia/views/enedis-service-subscription/DemandReportPage')
);

const EnedisServiceSubscriptionServiceListPage = lazy(() =>
  import('eficia/views/enedis-service-subscription/ServiceListPage')
);

export default function EnedisServiceSubscriptionRoutes() {
  return (
    <PrivateRoute
      path={ENEDIS_SERVICE_SUBSCRIPTION_PATH}
      render={({ match: { path }, location: { pathname: currentPath } }) => (
        <>
          {currentPath === path && <Redirect to={`${path}/demands`} />}
          <Route
            path={`${path}/demands`}
            component={EnedisServiceSubscriptionDemandListPage}
            exact
          />
          <Route
            path={`${path}/demands/report/:demandId`}
            component={EnedisServiceSubscriptionDemandReportPage}
          />
          <Route
            path={`${path}/demands/create`}
            component={EnedisServiceSubscriptionCreateDemandPage}
            exact
          />
          <Route
            path={`${path}/services`}
            component={EnedisServiceSubscriptionServiceListPage}
          />
        </>
      )}
    />
  );
}
