import React, { useState, useContext } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import languageCode from 'eficia/utilities/languageCode.json';
import { TranslationContext } from 'eficia/contexts/TranslationProvider';

import { Input } from './HeaderSelect.style';

export default function HeaderSelect() {
  const [cookies, setCookie] = useCookies();
  const { userLang } = cookies;
  const { currentLang, langList } = useContext(TranslationContext);
  const [lang, setLang] = useState(userLang || currentLang);

  const { i18n } = useTranslation();

  const handleChange = event => {
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value);
    setCookie('userLang', event.target.value);
  };

  function getNativeName(val) {
    const shortVal = val.split('-')[0];
    // Search nativeName in json when code === 'fr-fr'
    // If value doesn't exist, we search code === 'fr'
    const language =
      languageCode.find(item => item.code === val) ||
      languageCode.find(item => item.code === shortVal);

    return language.nativeName;
  }

  return (
    <>
      <Select value={lang} onChange={handleChange} input={<Input />}>
        {langList &&
          langList.map((item, index) => (
            <MenuItem key={index} value={item}>
              {getNativeName(item)}
            </MenuItem>
          ))}
      </Select>
    </>
  );
}
