import axiosConfig from './config/axiosConfig';

import { ENSYLOG_BASE_API } from './config/constants';

export default function harmonyApi() {
  return {
    async getId(siteId) {
      const query = await axiosConfig.get(
        `${ENSYLOG_BASE_API}/harmony/list-by-site?site-id=${siteId}`
      );
      return query;
    },
    async getObjects(harmonyId) {
      const query = await axiosConfig.get(
        `${ENSYLOG_BASE_API}/harmony/objects?harmony-id=${harmonyId}`
      );
      return query;
    },
    async getHarmonyList() {
      const query = await axiosConfig.get(
        `${ENSYLOG_BASE_API}/harmony/list`
      );
      return query;
    }
  };
}
