import {useCallback} from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';



// Récupérer les données de l'utilisateur connecté
export function useFetchConnectedUserData() {
  const client = eficiaClient();

  const fetcher = useCallback(() => {
    return client.user.getInfos();
  }, [client.user]);

  const { data, error, isFetching } = useQuery(['user-data'], fetcher);

  return {
    data: data?.data,
    error,
    isFetching
  };
}
