import React from 'react';
import { matchPath, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { List, Typography } from '@material-ui/core';

import SidebarMenuListItem from './SidebarMenuListItem';

const reduceChildRoutes = props => {
  const { location, items, page, depth } = props;

  if (page.content) {
    // Open on current menuItem if has content
    const open = matchPath(location.pathname, {
      path: page.to,
      exact: false
    });

    items.push(
      <SidebarMenuListItem
        depth={depth}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        open={Boolean(open)}
        title={page.label}
      >
        <div className="sidebar-menu-children py-2">
          <SidebarMenuList depth={depth + 1} pages={page.content} />
        </div>
      </SidebarMenuListItem>
    );
  } else {
    items.push(
      <SidebarMenuListItem
        depth={depth}
        href={page.to}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        title={page.label}
      />
    );
  }

  return items;
};

const SidebarMenuList = props => {
  const { pages, ...rest } = props;

  return (
    <List className="p-0">
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        []
      )}
    </List>
  );
};

SidebarMenuList.propTypes = {
  depth: PropTypes.number.isRequired,
  pages: PropTypes.array.isRequired
};

const SidebarMenu = props => {
  const { title, pages, className, component: Component, ...rest } = props;

  const { location } = useHistory();

  return (
    <Component {...rest} className={className}>
      {title && (
        <Typography className="app-sidebar-heading" variant="subtitle2">
          {title}
        </Typography>
      )}
      <SidebarMenuList depth={0} pages={pages} location={location} />
    </Component>
  );
};

SidebarMenu.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

SidebarMenu.defaultProps = {
  component: 'nav'
};

export default SidebarMenu;
