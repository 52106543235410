/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */

export function currentCode() {
  let lang = window.navigator.languages
    ? window.navigator.languages[0]
    : null;
  lang = lang
    || window.navigator.language
    || window.navigator.browserLanguage
    || window.navigator.userLanguage;

  if (lang.split('-').length === 1) {
    lang = `${lang}-${lang}`;
  }

  let shortCode = null;
  if (lang.indexOf('-') !== -1) shortCode = lang.split('-')[0];
  if (lang.indexOf('_') !== -1) shortCode = lang.split('_')[0];

  return { code: lang.toLowerCase(), shortCode };
}
