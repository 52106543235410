import React, { lazy } from 'react';

import { ON_CALL_HOME_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const OnCallPage = lazy(() =>
  import('eficia/views/on-call/home/OnCallHomePage')
);

export default function OncallRoutes() {
  return <PrivateRoute path={ON_CALL_HOME_PATH} component={OnCallPage} />;
}
