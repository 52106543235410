import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import { ELETRICITY_CURVES_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const ElectricityCurvePage = lazy(() =>
  import('eficia/views/electricy-curves/ElectricityCurvePage')
);

export default function ElectricityCurveRoutes() {
  return (
    <PrivateRoute
      path={ELETRICITY_CURVES_PATH}
      render={({ match: { path } }) => (
        <>
          <Route path={path} component={ElectricityCurvePage} exact />
        </>
      )}
    />
  );
}
