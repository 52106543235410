import PropTypes from 'prop-types';
import styled from 'styled-components';

import logoEficiaWhite from 'assets/eficia/logos/short_logo_white.png';
import logoEficiaDark from 'assets/eficia/logos/short_logo.png';

export const Logo = styled.img.attrs(({ isDark }) => ({
  src: isDark ? logoEficiaDark : logoEficiaWhite
}))`
  width: ${({ width = 70 }) => width}px
`;

Logo.propTypes = {
  // Display dark version of logo
  isDark: PropTypes.bool
};
