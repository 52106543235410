export const ENSYLOG_BASE_API = `${process.env.REACT_APP_ENSYLOG_API_URL}/api`;
export const GLOBAL_BASE_API = 'https://global.ensylog.com/api';
export const APIARY_API = 'https://private-df8425-eficia.apiary-mock.com';

// TODO ALERTS_BIS: Ce endpoint est utilisé uniquement dans la view alerts-bis et voué à être supprimé du projet
export const ALERTS_BASE_API = 'https://alerts.ensylog.com/api';
export const ALERTS_API = `${ALERTS_BASE_API}/alerts`;

// alert
export const ALERT_API = `${ENSYLOG_BASE_API}/alert`;

// enedis-alerts
export const ENEDIS_ALERTS_API = `${ENSYLOG_BASE_API}/enedis`;

// enedis-service-subscription
export const ENEDIS_SERVICE_SUBSCRIPTION_API = `${ENSYLOG_BASE_API}/enedis`;

// site-alert
export const SITE_ALERT_API = `${ENSYLOG_BASE_API}/site-alert`;

// translation
export const TRANSLATION_API = `${ENSYLOG_BASE_API}/translation`;

// consumption(enedis)
export const CONSUMPTION_API = `${ENSYLOG_BASE_API}/consumption`;

// sites
export const SITES_API = `${ENSYLOG_BASE_API}/site`;

// on-call
export const ON_CALL_API = `${ENSYLOG_BASE_API}/astreinte/operator`;

// harmony-script
export const HARMONY_SCRIPT = `${ENSYLOG_BASE_API}/harmony/scripts`;

// harmony-writing
export const HARMONY_WRITING = `${ENSYLOG_BASE_API}/harmony/mass-object-writing`;
export const HARMONY_WRITING_EXPORT = `${ENSYLOG_BASE_API}/harmony/export-mass-object-writing`;

// wems-query
export const WEMS_QUERY = `${ENSYLOG_BASE_API}/wems`;

// user
export const SLUGS_API = `${ENSYLOG_BASE_API}/acl/list-authorized-pages`;

export const USER_API = `${ENSYLOG_BASE_API}/user/me`;
