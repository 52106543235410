import axiosConfig from './config/axiosConfig';

import { CONSUMPTION_API } from './config/constants';

export default function consumptionsApi() {
  return {
    async getAll(search, params) {
      const query = await axiosConfig.post(
        `${CONSUMPTION_API}/enedis-consumption-demands${search}`,
        params
      );
      return query;
    },
    async getReports(reportId, search) {
      const query = await axiosConfig.get(
        `${CONSUMPTION_API}/enedis-consumption-demand/${reportId}/report${search}`
      );
      return query;
    },
    async postConsumptionDemand(params) {
      const query = await axiosConfig.post(
        `${CONSUMPTION_API}/enedis-consumption-demand`,
        params
      );
      return query;
    }
  };
}
