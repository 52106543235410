import alertsApi from './alerts';
import siteAlertsApi from './siteAlerts';
import harmonyApi from './harmony';
import translationsApi from './translations';
import consumptionsApi from './consumptions';
import sitesApi from './sites';
import onCallApi from './onCall';
import harmonyScriptApi from './harmonyScripts';
import wemsQueryApi from './wemsQuery';
import softwareByAlricApi from './softwareByAlric';
import userApi from './user';
import alertsBisApi from './alertsBis';
import enedisAlertsApi from './enedisAlerts';
import enedisServiceSubscriptionsApi from './enedisServiceSubscriptions';
import enedisMeasurementApi from './enedisMeasurements';
import harmonyWritingApi from './harmonyWritings';

export default function eficiaClient() {
  return {
    alerts: alertsApi(),
    alertsBis: alertsBisApi(),
    siteAlerts: siteAlertsApi(),
    harmony: harmonyApi(),
    translations: translationsApi(),
    consumptions: consumptionsApi(),
    sites: sitesApi(),
    onCall: onCallApi(),
    harmonyScripts: harmonyScriptApi(),
    wemsQuery: wemsQueryApi(),
    softwareByAlric: softwareByAlricApi(),
    user: userApi(),
    enedisAlerts: enedisAlertsApi(),
    enedisServiceSubscriptions: enedisServiceSubscriptionsApi(),
    enedisMeasurements: enedisMeasurementApi(),
    harmonyWritings: harmonyWritingApi()
  };
}
