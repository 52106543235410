import axios from 'axios';

import { APIARY_API } from './config/constants';

export default function softwareByAlricApi() {
  return {
    async getAllSoftware() {
      const query = await axios.get(`${APIARY_API}/software-by-alric`);
      return query;
    }
  };
}
