import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Logo from 'eficia/components/atoms/Logo';

import { Div, StyledLetterLogo } from './HeaderLogo.style';

const HeaderLogo = (props) => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <>
      <Div
        sidebarToggle={sidebarToggle}
        className={`${clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}`}
      >
        <Link className="header-logo-wrapper-link" to="/">
          {sidebarToggle ? <StyledLetterLogo /> : <Logo />}
        </Link>
      </Div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

HeaderLogo.propTypes = {
  sidebarToggle: PropTypes.bool,
  sidebarHover: PropTypes.bool
};

export default connect(mapStateToProps)(HeaderLogo);
