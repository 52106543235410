import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import { ALERTS_BIS_HOME_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const AlertBisHomePage = lazy(() =>
  import('eficia/views/alerts-bis/home/AlertsHomePage')
);
const AlertBisCreatePage = lazy(() =>
  import('eficia/views/alerts-bis/create/AlertsCreatePage')
);
const AlertBisEditPage = lazy(() =>
  import('eficia/views/alerts-bis/edit/AlertsEditPage')
);
const AlertBisDetailPage = lazy(() =>
  import('eficia/views/alerts-bis/detail/AlertDetailPage')
);

export default function AlertBisRoutes() {
  return (
    <PrivateRoute
      path={ALERTS_BIS_HOME_PATH}
      render={({ match: { path } }) => (
        <>
          <Route path={path} component={AlertBisHomePage} exact />
          <Route path={`${path}/create`} component={AlertBisCreatePage} />
          <Route path={`${path}/edit/:id`} component={AlertBisEditPage} />
          <Route path={`${path}/detail/:id`} component={AlertBisDetailPage} />
        </>
      )}
    />
  );
}
