import { ListItem } from '@material-ui/core';
import styled from 'styled-components';

export const SideBarList = styled(ListItem)`
  svg {
    min-width: 20px;
    font-size: 17px;
    margin-right: ${({ theme }) => theme.spacing(2)}px;;
  }
`;
