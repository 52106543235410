import axiosConfig from './config/axiosConfig';
import { ENEDIS_SERVICE_SUBSCRIPTION_API } from './config/constants';

export default function enedisServiceSubscriptionsApi() {
  return {
    async create(params) {
      const query = await axiosConfig.post(
        `${ENEDIS_SERVICE_SUBSCRIPTION_API}/service-subscription-demand`,
        params
      );
      return query;
    },
    async createCancel(params) {
      const query = await axiosConfig.post(
        `${ENEDIS_SERVICE_SUBSCRIPTION_API}/cancel-service-subscription-demand`,
        params
      );
      return query;
    },
    async getOneDemand(demandId) {
      const query = await axiosConfig.get(
        `${ENEDIS_SERVICE_SUBSCRIPTION_API}/service-subscription-demand/${demandId}`
      );
      return query;
    },
    async getOneSiteDemand(demandId, search) {
      const query = await axiosConfig.get(
        `${ENEDIS_SERVICE_SUBSCRIPTION_API}/site-service-subscription-demands/${demandId}${search}`
      );
      return query;
    },
    async getAllSiteDemand(search, params) {
      const query = await axiosConfig.post(
        `${ENEDIS_SERVICE_SUBSCRIPTION_API}/site-service-subscriptions${search}`,
        params
      );
      return query;
    },
    async export(demandId) {
      const query = await axiosConfig.get(
        `${ENEDIS_SERVICE_SUBSCRIPTION_API}/service-subscription-demand/${demandId}/export-csv`
      );
      return query;
    },
    async getAllDemands(search) {
      const query = await axiosConfig.get(
        `${ENEDIS_SERVICE_SUBSCRIPTION_API}/service-subscription-demands${search}`
      );
      return query;
    }
  };
}
