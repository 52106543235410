import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { UserContext } from 'eficia/contexts/UserProvider';

// Example slugs
// const slugs = [
//   'alerts-bis',
//   'history-requests',
//   'translation',
//   'oncall-spain',
//   'software-by-alric',
//   'alerts',
//   'harmony-scripts',
//   'wems-query',
//   'enedis-alerts'
// ];

export default function PrivateRoute({ ...props }) {
  const { slugs } = useContext(UserContext);
  const { path } = props;

  const pageSlug = path.substring(1);
  // On redirige sur une page d'erreur si l'utilisateur n'a pas accès à la page
  if (!slugs.includes(pageSlug)) {
    return (
      <Route path={path} render={() => <Redirect to="/forbidden-access" />} />
    );
  }

  return <Route path={path} {...props} />;
}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired
};
