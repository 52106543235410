import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import { HISTORY_REQUESTS_HOME_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const HistoryRequestHomePage = lazy(() =>
  import('eficia/views/history-requests/home/HistoryRequestHomePage')
);
const HistoryRequestsDetailPage = lazy(() =>
  import('eficia/views/history-requests/detail/HistoryRequestDetailPage')
);

export default function HistoryRequestRoutes() {
  return (
    <PrivateRoute
      path={HISTORY_REQUESTS_HOME_PATH}
      render={({ match: { path } }) => (
        <>
          <Route path={path} component={HistoryRequestHomePage} exact />
          <Route
            path={`${path}/detail/:reportId`}
            component={HistoryRequestsDetailPage}
          />
        </>
      )}
    />
  );
}
