import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import { ELETRICITY_CURVES_MONOSITE_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const ElectricityCurveMonositePage = lazy(() =>
  import(
    'eficia/views/electricity-curves-monosite/ElectricityCurveMonositePage'
  )
);

export default function ElectricityCurveMonositeRoutes() {
  return (
    <PrivateRoute
      path={ELETRICITY_CURVES_MONOSITE_PATH}
      render={({ match: { path } }) => (
        <>
          <Route path={path} component={ElectricityCurveMonositePage} exact />
        </>
      )}
    />
  );
}
