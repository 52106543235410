import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useCookies } from 'react-cookie';

import { currentCode } from 'eficia/utilities/translation';

import { STAGING_ENVIRONMENT } from 'eficia/constants/global';
import { useFetchLocaleList } from './services/translation/useTranslation';

const TranslationContext = React.createContext();
const { Provider } = TranslationContext;

function TranslationProvider({ children }) {
  const [langResources, setLangResources] = useState(null);
  const [currentLang, setCurrentLang] = useState(null);
  const { data: langList } = useFetchLocaleList();

  const [cookies] = useCookies();
  const { userLang } = cookies;

  function formatCode(code) {
    const split = code.split('-');
    split[1] = split[1].toUpperCase();
    return split.join('-');
  }

  const apiUrl =
    process.env.REACT_APP_ENVIRONMENT === STAGING_ENVIRONMENT
      ? 'https://dmka8lq2bwqpe.cloudfront.net'
      : 'https://d35hnv6ljipzpz.cloudfront.net';

  useEffect(() => {
    if (langList) {
      const requests = langList.map(code =>
        axios.get(`${apiUrl}/${code}.json`)
      );

      const formattedCode = langList.map(res => formatCode(res));
      axios.all(requests).then(res => {
        const formattedLang = formattedCode.reduce(
          (acc, curr, index) => ({
            ...acc,
            [curr]: { translation: res[index].data }
          }),
          {}
        );
        setLangResources(formattedLang);
      });

      // Set navigatorLang
      let language;
      // TODO : Translation - Clean condition
      language =
        langList && langList.find(item => item.includes(currentCode().code));

      if (!language && langList) {
        language = langList.find(item =>
          item.includes(currentCode().shortCode)
        );
      }

      // Use value of cookie if exist
      setCurrentLang(userLang || language);
    }
  }, [langList, userLang, apiUrl]);

  if (!langResources || !currentLang) return null;

  i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: false,
      lng: currentLang,
      keySeparator: true,
      resources: langResources
    });

  return <Provider value={{ currentLang, langList }}>{children}</Provider>;
}

TranslationProvider.propTypes = {
  children: PropTypes.node
};

export { TranslationProvider, TranslationContext };
