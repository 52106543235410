import React from 'react';
import PropTypes from 'prop-types';

import { useFetchSlugs } from './services/user/useFetchSlugs';
import { useFetchConnectedUserData } from './services/user/useFetchConnectedUserData';

const UserContext = React.createContext();
const { Provider } = UserContext;

function UserProvider({ children }) {
  const { data: slugsData } = useFetchSlugs();
  const { data: userInfos } = useFetchConnectedUserData();

  if (!slugsData) {
    return null;
  }

  if (!userInfos) {
    return null;
  }

  const contextValue = {
    slugs: slugsData.data.slugs,
    userInfos
  };

  return <Provider value={contextValue}>{children}</Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { UserProvider, UserContext };
