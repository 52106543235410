import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { Hidden, IconButton, AppBar, Box } from '@material-ui/core';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import {
  setSidebarToggle,
  setSidebarToggleMobile
} from 'eficia/reducers/ThemeOptions';
import Logo from 'eficia/components/atoms/Logo';

import HeaderLogo from './components/logo';
import HeaderSelect from './components/select';

const Header = props => {
  const history = useHistory();
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  function handleLogoClick() {
    history.go(0);
  }

  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle
  } = props;

  // template props default
  const isCollapsedLayout = false;

  return (
    <>
      <AppBar
        color="primary"
        className={clsx('app-header', {
          'app-header-collapsed-sidebar': isCollapsedLayout
        })}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 1}
      >
        {!isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          {/* View icon on responsive */}
          <Hidden lgUp>
            <Box
              className="app-logo-wrapper"
              title="Carolina React Admin Dashboard with Material-UI PRO"
            >
              <Logo onClick={handleLogoClick} className="app-logo-link" />
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!isCollapsedLayout && (
                <Box
                  className={clsx('btn-toggle-collapse', {
                    'btn-toggle-collapse-closed': sidebarToggle
                  })}
                >
                  <IconButton
                    color="inherit"
                    onClick={toggleSidebar}
                    size="medium"
                    className="btn-inverse"
                  >
                    {sidebarToggle ? (
                      <MenuRoundedIcon />
                    ) : (
                      <MenuOpenRoundedIcon />
                    )}
                  </IconButton>
                </Box>
              )}
            </Box>
          </Hidden>
          <Box className="d-flex align-items-center">
            <HeaderSelect />
            <Box className="toggle-sidebar-btn-mobile">
              <IconButton
                color="inherit"
                onClick={toggleSidebarMobile}
                size="medium"
              >
                {sidebarToggleMobile ? (
                  <MenuOpenRoundedIcon />
                ) : (
                  <MenuRoundedIcon />
                )}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </>
  );
};

Header.propTypes = {
  headerFixed: PropTypes.bool,
  headerShadow: PropTypes.bool,
  setSidebarToggle: PropTypes.func,
  setSidebarToggleMobile: PropTypes.func,
  sidebarToggle: PropTypes.bool,
  sidebarToggleMobile: PropTypes.bool
};

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
