import axios from 'axios';
import axiosConfig from './config/axiosConfig';

import { GLOBAL_BASE_API, ON_CALL_API } from './config/constants';

export default function onCallApi() {
  return {
    async getEficiaUsers() {
      const query = await axios.get(
        `${GLOBAL_BASE_API}/users/getEficiaUsers.php`
      );
      return query;
    },
    async getCurrentNumber() {
      const query = await axiosConfig.get(ON_CALL_API);
      return query;
    },
    async addNewNumber(params) {
      const query = await axiosConfig.post(ON_CALL_API, params);
      return query;
    }
  };
}
