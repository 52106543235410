import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const LocationContext = React.createContext();
const { Provider } = LocationContext;

function LocationProvider({ children }) {
  const [path, setPath] = useState(null);

  const contextValue = {
    setLocation: useCallback(location => setPath(location), []),
    savedLocation: path
  };

  return <Provider value={contextValue}>{children}</Provider>;
}

LocationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { LocationProvider, LocationContext };
