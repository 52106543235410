import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import { HARMONY_WRITING_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const HarmonyWritingHomePage = lazy(() =>
  import('eficia/views/harmony-writing/HomePage')
);

const HarmonyWritingCreatePage = lazy(() =>
  import('eficia/views/harmony-writing/CreatePage')
);
const CreatePageFromModel = lazy(() =>
  import('eficia/views/harmony-writing/CreatePageFromModel')
);

const HarmonyWritingReportPage = lazy(() =>
  import('eficia/views/harmony-writing/ReportPage')
);

export default function HarmonyWritingRoutes() {
  return (
    <PrivateRoute
      path={HARMONY_WRITING_PATH}
      render={({ match: { path } }) => (
        <>
          <Route path={path} component={HarmonyWritingHomePage} exact />
          <Route path={`${path}/create`} component={HarmonyWritingCreatePage} />
          <Route
            path={`${path}/create-from-model/:massObjectWritingId`}
            component={CreatePageFromModel}
          />
          <Route
            path={`${path}/report/:massObjectWritingId`}
            component={HarmonyWritingReportPage}
          />
        </>
      )}
    />
  );
}
