import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import SidebarHeader from 'eficia/views/template/SidebarHeader';
import SidebarMenu from 'eficia/views/template/Sidebar/SidebarMenu';
import { UserContext } from 'eficia/contexts/UserProvider';
import {
  setSidebarToggleMobile,
  setSidebarHover,
  setSidebarToggle
} from 'eficia/reducers/ThemeOptions';
import { PRODUCTION_ENVIRONMENT } from 'eficia/constants/global';

import { NAV_CONFIG } from './constants';

const Sidebar = props => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,
    sidebarHover,
    setSidebarHover,
    sidebarToggle,
    sidebarShadow
  } = props;

  const { slugs } = useContext(UserContext);
  const location = useLocation();
  const nav = getNav();

  // On filtre nav que lorsqu'on est en production afin de s'assurer que l'utilisateur a accès seulement au page dont il a accès
  function getNav() {
    return process.env.REACT_APP_ENVIRONMENT === PRODUCTION_ENVIRONMENT
      ? NAV_CONFIG.map(config => {
          const { content } = config;
          const filteredContent = content.filter(
            ({ slug }) =>
              slugs.includes(slug) && location.pathname.split('/')[1] === slug
          );
          return { ...config, content: filteredContent };
        })
      : // STAGING
        NAV_CONFIG.map(config => {
          const { content } = config;
          const filteredContent = content.filter(
            ({ slug }) =>
              // liste des pages à cacher de la nav
              !['oncall-spain', 'software-by-alric', 'alerts-bis'].includes(
                slug
              )
          );
          return { ...config, content: filteredContent };
        });
  }

  const toggleHoverOn = () => setSidebarHover(true);
  const toggleHoverOff = () => setSidebarHover(false);

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const sidebarMenuContent = (
    <div
      className={clsx({
        'app-sidebar-nav-close': sidebarToggle && !sidebarHover
      })}
    >
      {nav.map((list, index) => (
        <SidebarMenu
          key={index}
          component="div"
          pages={list.content}
          title={list.label}
        />
      ))}
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg"
        >
          <SidebarHeader />
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-close': sidebarToggle,
            'app-sidebar-wrapper-open': sidebarHover,
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          open={sidebarToggle}
          elevation={sidebarShadow ? 11 : 1}
        >
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed,
              'app-sidebar-collapsed': sidebarToggle && !sidebarHover
            })}
          >
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </>
  );
};

Sidebar.propTypes = {
  setSidebarToggleMobile: PropTypes.func,
  sidebarToggleMobile: PropTypes.bool,
  sidebarFixed: PropTypes.bool,
  sidebarHover: PropTypes.bool,
  setSidebarHover: PropTypes.func,
  sidebarToggle: PropTypes.bool,
  sidebarShadow: PropTypes.bool
};

const mapStateToProps = state => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarHover: enable => dispatch(setSidebarHover(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
