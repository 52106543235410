import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Collapse } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { SideBarList } from './SidebarListItem.style';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarMenuListItem = props => {
  const {
    title,
    href,
    depth,
    children,
    icon,
    className,
    open: openProp,
    label: Label,
    ...rest
  } = props;

  const [open, setOpen] = useState(openProp);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const handleToggle = () => {
    setOpen(open => !open);
  };

  let paddingLeft = 14;

  if (depth > 0) {
    paddingLeft = 32 + 20 * depth;
  }

  const style = {
    paddingLeft
  };

  if (children) {
    return (
      <SideBarList
        {...rest}
        className={clsx('app-sidebar-item', className)}
        disableGutters
      >
        <Button
          color="primary"
          className={clsx('app-sidebar-button', { active: open })}
          onClick={handleToggle}
          style={style}
        >
          {icon && icon}
          <span>{t(title)}</span>
          {open ? (
            <ExpandLessIcon className="sidebar-expand-icon" color="inherit" />
          ) : (
            <ExpandLessIcon
              className="sidebar-expand-icon sidebar-expand-icon-rotate"
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </SideBarList>
    );
  }

  return (
    <SideBarList
      {...rest}
      className={clsx('app-sidebar-item', className)}
      disableGutters
    >
      <Button
        color="primary"
        disableRipple
        variant="text"
        className={clsx('app-sidebar-button-wrapper', `depth-${depth}`, {
          active: pathname.includes(`${href}/`)
        })}
        component={CustomRouterLink}
        exact
        style={style}
        to={href}
      >
        {/* {icon && <icon className="app-sidebar-icon" />} */}
        {icon && icon}
        {t(title)}
        {Label && (
          <span className="menu-item-label">
            <Label />
          </span>
        )}
      </Button>
    </SideBarList>
  );
};

SidebarMenuListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number,
  href: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

SidebarMenuListItem.defaultProps = {
  depth: 0,
  open: false
};

export default SidebarMenuListItem;
