import dotProp from 'dot-prop';

import { streamDownload } from 'eficia/utilities/streamDownload';

import axiosConfig from './config/axiosConfig';
import { ENSYLOG_BASE_API } from './config/constants';

export default function enedisMeasurementsApi() {
  return {
    async exportMeasurements(params, curveType) {
      return streamDownload(
        `${ENSYLOG_BASE_API}/enedis/measurement/${curveType}/export-csv`,
        params
      );
    },
    async getMeasurements(params, aggregate, type) {
      // Response of request
      const result = await axiosConfig.post(
        `${ENSYLOG_BASE_API}/enedis/measurement/${type}`,
        params
      );

      function formatDataForChart(data, isAggregate) {
        let formatData = {};

        if (isAggregate) {
          const siteNameString = `${data.siteNames.length} site(s)`;
          const newData = data.values.map(({ time, value }) => ({
            date: +new Date(time),
            [siteNameString]: value
          }));

          formatData = { dataList: newData, keyList: [siteNameString] };
        } else {
          const keyList = [];
          const newData = [];
          for (let i = 0; i < data.length; i += 1) {
            const date = +new Date(data[i].time);
            if (!keyList.includes(data[i].name)) {
              keyList.push(data[i].name);
            }
            newData[date] = { ...newData[date], [data[i].name]: data[i].value };
          }
          formatData = { dataList: newData, keyList };
        }

        const consumptions = Object.entries(formatData.dataList).map(
          ([date, values]) => ({
            date: Number(date),
            ...values
          })
        );

        return {
          data: consumptions,
          keys: formatData.keyList
        };
      }

      return {
        data: formatDataForChart(result.data.data, aggregate),
        nextId: result.data.meta.nextId
      };
    },
    async exportMonositeMeasurements(params, curveType) {
      return streamDownload(
        `${ENSYLOG_BASE_API}/enedis/measurement/site-${curveType}/export-csv`,
        params
      );
    },
    async getpowerSubscriptionUnit(params, type) {
      // Response of request
      const result = await axiosConfig.post(
        `${ENSYLOG_BASE_API}/enedis/measurement/site-consumption`,
        params
      );

      return {
        powerSubscriptionUnit: dotProp.get(
          result,
          'data.data.curveItems.data.0.powerSubscriptionUnit',
          ''
        )
      };
    },
    async getMonositeMeasurements(params, type) {
      // Response of request
      const result = await axiosConfig.post(
        `${ENSYLOG_BASE_API}/enedis/measurement/site-${type}`,
        params
      );

      function formatDataForChart(data) {
        const formatData = [];
        if (data.curveItems.data.length === 0) {
          return {
            data: []
          };
        }
        for (let i = 0; i < data.curveItems.data.length; i += 1) {
          const date = +new Date(data.curveItems.data[i].time);
          formatData[date] = {
            'power-subscription':
              data.curveItems.data[i].powerSubscriptionValue,
            [type]: data.curveItems.data[i].value
          };
        }
        const consumptions = Object.entries(formatData).map(
          ([date, values]) => ({
            date: Number(date),
            ...values
          })
        );

        return {
          data: consumptions,
          keys: [type],
          powerSubscriptionUnit: data.curveItems.data[0].powerSubscriptionUnit
        };
      }

      return {
        data: formatDataForChart(result.data.data),
        nextId: result.data.meta.nextId
      };
    }
  };
}
