import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import clsx from 'clsx';

import { ALERTS_BIS_HOME_PATH } from 'eficia/constants/paths';
import { IconButton, Box, Tooltip } from '@material-ui/core';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

import { setSidebarToggleMobile } from 'eficia/reducers/ThemeOptions';

import Logo from 'eficia/components/atoms/Logo';

const SidebarHeader = props => {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
    sidebarHover
  } = props;

  return (
    <>
      <div
        className={clsx('app-sidebar-header', {
          'app-sidebar-header-close': sidebarToggle && !sidebarHover
        })}
      >
        <Box
          className="header-logo-wrapper"
          title="Carolina React Admin Dashboard with Material-UI PRO"
        >
          <Link to={ALERTS_BIS_HOME_PATH} className="header-logo-wrapper-link">
            <Logo isDark />
          </Link>
        </Box>
        <Box
          className={clsx('app-sidebar-header-btn', {
            'app-sidebar-header-btn-close': sidebarToggle && !sidebarHover
          })}
        >
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton color="inherit" onClick={toggleSidebar} size="medium">
              {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton
              color="inherit"
              onClick={toggleSidebarMobile}
              size="medium"
            >
              {sidebarToggleMobile ? (
                <MenuOpenRoundedIcon />
              ) : (
                <MenuRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </>
  );
};

SidebarHeader.propTypes = {
  setSidebarToggle: PropTypes.func,
  setSidebarToggleMobile: PropTypes.func,
  sidebarHover: PropTypes.bool,
  sidebarToggle: PropTypes.bool,
  sidebarToggleMobile: PropTypes.bool
};
const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
