export const HARMONY_LOG_TYPE = 'HarmonyLogRule';
export const SITE_HARMONY_LOG_TYPE = 'SiteHarmonyLogRule';
export const OPENING_TYPE = 'OpeningRule';
export const SITE_OPENING_TYPE = 'SiteOpeningRule';

export const RULE_TYPES = [
  {
    text: 'harmony',
    value: HARMONY_LOG_TYPE,
    label: 'alert.view.create.modal.input.rule_type.harmony'
  },
  {
    text: 'horaire',
    value: OPENING_TYPE,
    label: 'alert.view.create.modal.input.rule_type.timetable'
  }
];

export const SITE_RULE_TYPES = [
  {
    text: 'harmony',
    value: SITE_HARMONY_LOG_TYPE,
    label: 'alert.view.create.modal.input.rule_type.harmony'
  },
  {
    text: 'horaire',
    value: SITE_OPENING_TYPE,
    label: 'alert.view.create.modal.input.rule_type.timetable'
  }
];

export const TAG_TYPE = 'tag';
export const ADDRESS_TYPE = 'address';

export const TYPES = {
  [ADDRESS_TYPE]: {
    value: 'address',
    label: 'alert.view.create.modal.input.address_object',
    descriptionText: {
      singular: 'alert.view.create.modal.input.address_object.text.singular',
      plurial: 'alert.view.create.modal.input.address_object.text.plurial'
    }
  },
  [TAG_TYPE]: {
    value: 'tag',
    label: 'alert.view.create.modal.input.tag_object',
    descriptionText: {
      singular: 'alert.view.create.modal.input.tag_object.text.singular',
      plurial: 'alert.view.create.modal.input.tag_object.text.plurial'
    }
  }
};

export const NUMERIC_COMPARISON = 'numeric_comparison';
export const BOOLEAN_COMPARISON = 'boolean_comparison';
export const NUMERIC_RANGE = 'numeric_range';
export const STRING_COMPARISON = 'string_comparison';
export const LAST_LOG = 'last_log';
export const VALUE_CHANGE = 'value_change';

export const HARMONY_RULE_LIST = [
  {
    value: BOOLEAN_COMPARISON,
    label: 'alert.view.create.modal.operator.boolean_comparison'
  },
  {
    value: NUMERIC_COMPARISON,
    label: 'alert.view.create.modal.operator.numeric_comparison'
  },
  {
    value: NUMERIC_RANGE,
    label: 'alert.view.create.modal.operator.numeric_range'
  },
  {
    value: STRING_COMPARISON,
    label: 'alert.view.create.modal.operator.string_comparaison'
  },
  {
    value: LAST_LOG,
    label: 'alert.view.create.modal.operator.last_log'
  },
  {
    value: VALUE_CHANGE,
    label: 'alert.view.create.modal.operator.value_change'
  }
];

export const TIMETABLE_OPERATOR = [
  {
    value: 'Rules.OpenClose.IsOpen',
    label: 'alert.view.create.form.opening_state.opening'
  },
  {
    value: 'Rules.OpenClose.IsClose',
    label: 'alert.view.create.form.opening_state.closing'
  }
];

export const HARMONY_OPERATORS = {
  [NUMERIC_COMPARISON]: [
    {
      value: 'Rules.Numeric.LogValueEqualTo',
      label: 'alert.view.create.modal.operator.numeric_comparison.equal'
    },
    {
      value: 'Rules.Numeric.LogValueNotEqualTo',
      label: 'alert.view.create.modal.operator.numeric_comparison.not_equal'
    },
    {
      value: 'Rules.Numeric.LogValueLowerThan',
      label: 'alert.view.create.modal.operator.numeric_comparison.less_than'
    },
    {
      value: 'Rules.Numeric.LogValueGreaterThan',
      label: 'alert.view.create.modal.operator.numeric_comparison.greater_than'
    },
    {
      value: 'Rules.Numeric.LogValueLowerOrEqualTo',
      label:
        'alert.view.create.modal.operator.numeric_comparison.less_than_or_equal'
    },
    {
      value: 'Rules.Numeric.LogValueGreaterOrEqualTo',
      label:
        'alert.view.create.modal.operator.numeric_comparison.greater_than_or_equal'
    }
  ],
  [NUMERIC_RANGE]: [
    {
      value: 'Rules.Numeric.LogValueBetweenOrEqual',
      label: 'alert.view.create.modal.operator.numeric_range.between_or_equal'
    },
    {
      value: 'Rules.Numeric.LogValueOutside',
      label: 'alert.view.create.modal.operator.numeric_range.outside'
    },
    {
      value: 'Rules.Numeric.LogValueOutsideOrEqual',
      label: 'alert.view.create.modal.operator.numeric_range.outside_or_equal'
    }
  ],
  [BOOLEAN_COMPARISON]: [
    {
      value: 'Rules.Boolean.LogValueEqualToFalse',
      label: 'alert.view.create.modal.operator.boolean_comparison.0'
    },
    {
      value: 'Rules.Boolean.LogValueEqualToTrue',
      label: 'alert.view.create.modal.operator.boolean_comparison.1'
    }
  ],
  [STRING_COMPARISON]: [
    {
      value: 'Rules.String.LogValueEqualTo',
      label: 'alert.view.create.modal.operator.string_comparaison.equal'
    },
    {
      value: 'Rules.String.LogValueNotEqualTo',
      label: 'alert.view.create.modal.operator.string_comparaison.not_equal'
    },
    {
      value: 'Rules.String.LogValueStartsWith',
      label: 'alert.view.create.modal.operator.string_comparaison.start_with'
    },
    {
      value: 'Rules.String.LogValueEndsWith',
      label: 'alert.view.create.modal.operator.string_comparaison.ends_with'
    },
    {
      value: 'Rules.String.LogValueContains',
      label: 'alert.view.create.modal.operator.string_comparaison.contains'
    },
    {
      value: 'Rules.String.LogValueDoesNotContain',
      label:
        'alert.view.create.modal.operator.string_comparaison.does_not_contain'
    },
    {
      value: 'Rules.String.LogValueIn',
      label: 'alert.view.create.modal.operator.string_comparaison.valueIn'
    },
    {
      value: 'Rules.String.LogValueNotIn',
      label: 'alert.view.create.modal.operator.string_comparaison.valueOut'
    }
  ],
  [LAST_LOG]: [
    {
      value: 'Rules.NoLogFor',
      label: 'alert.view.create.modal.operator.last_log'
    }
  ],
  [VALUE_CHANGE]: [
    {
      value: 'Rules.LogValueChange',
      label: 'alert.view.create.modal.operator.value_change'
    }
  ]
};
