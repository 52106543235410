export function soustractDays(days) {
  return new Date(Date.now() - days * 24 * 60 * 60 * 1000);
}

export function formatDateIntoString(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
}

export function formatDateFromToIntoString(dateStart, dateEnd) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const from = new Date(dateStart).toLocaleDateString('fr-FR', options);
  const to = new Date(dateEnd).toLocaleDateString('fr-FR', options);

  return `du ${from} au ${to}`;
}

export function formatDateToString(date) {
  return new Date(date).toLocaleString();
}
