import { ADDRESS_TYPE, HARMONY_OPERATORS, TAG_TYPE } from './constants/AlertRulesConstants';

export function getRuleName(ruleKey) {
  const ruleName = [];
  Object.entries(HARMONY_OPERATORS).map(ruleList =>
    ruleList[1].map(rule => {
      if (rule.value === ruleKey) {
        ruleName.push(ruleList[0]);
      }
      return null;
    })
  );

  return ruleName[0];
}

export function formatRule(rule) {

  const commonData = {
    type: rule.ruleType,
    name: rule.ruleOperatorName
  };

  if (rule.address?.length > 0 && rule.type === ADDRESS_TYPE) {
    commonData.objectAddresses = rule.address;
  }

  if (rule.tags?.length > 0 && rule.type === TAG_TYPE) {
    commonData.objectTags = rule.tags;
  }

  const duration =
  rule.duration === undefined ? 0 : parseInt(rule.duration);
const optionalDuration = duration === 0 ? null : { duration };

switch (rule.ruleOperatorName) {
  case 'Rules.NoLogFor':
    return {
      ...commonData,
      payload: {
        required: {
          duration
        },
        optional: null
      }
    };
  case 'Rules.Numeric.LogValueOutside':
  case 'Rules.Numeric.LogValueBetweenOrEqual':
  case 'Rules.Numeric.LogValueOutsideOrEqual':
    return {
      ...commonData,
      payload: {
        required: {
          start: rule.start,
          end: rule.end
        },
        optional: optionalDuration
      }
    };
  case 'Rules.LogValueChange':
  case 'Rules.Boolean.LogValueEqualToFalse':
  case 'Rules.Boolean.LogValueEqualToTrue':
    return {
      ...commonData,
      payload: {
        required: null,
        optional: optionalDuration
      }
    };
  case 'Rules.String.LogValueIn':
  case 'Rules.String.LogValueNotIn':
    return {
      ...commonData,
      payload: {
        required: {
          values: rule.values
        },
        optional: optionalDuration
      }
    };
  case 'Rules.OpenClose.IsOpen':
  case 'Rules.OpenClose.IsClose':
    return {
      type: rule.ruleType,
      name: rule.ruleOperatorName,
      payload: {
        required: null,
        optional: null
      }
    };
  default:
    return {
      ...commonData,
      payload: {
        required: {
          value: rule.value
        },
        optional: optionalDuration
      }
    };
}
}