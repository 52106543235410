// src/common/providers/APIErrorProvider/index.js
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

import Notification from 'eficia/components/molecules/Notification';

const NotificationContext = React.createContext();
const { Provider } = NotificationContext;

function NotificationProvider({ children }) {
  const [notification, setNotification] = useState(null);
  const addNotification = ({ message, type, duration }) =>
    setNotification({ message, type, duration });
  const removeNotification = () => setNotification(null);

  const contextValue = {
    notification,
    addNotification: useCallback(
      (message, status, duration) => addNotification(message, status, duration),
      []
    ),
    removeNotification: useCallback(() => removeNotification(), [])
  };

  return (
    <Provider value={contextValue}>
      <Notification />
      {children}
    </Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node
};

export { NotificationProvider, NotificationContext };
