import { useCallback } from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

export function useFetchLocaleList() {
  const client = eficiaClient();

  const fetcher = useCallback(() => {
    return client.translations.getListLocales();
  }, [client.translations]);

  const { data, error, isFetching } = useQuery(['lang'], fetcher);

  return {
    data: data?.data,
    error,
    isFetching
  };
}
